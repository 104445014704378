import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import VideoEmbed from "../../atoms/VideoEmbed"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import MediaTextBlock, { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"

const DatoVideoWithText = (props: Queries.DatoVideoWithTextFragment) => {
  //console.log('Rendering DatoVideoWithText', props)
  const { layout = "Tile", textBackground = "BrandWhite" } = props

  return (
    <MediaTextBlock
      id={slugify(props.title)}
      layout={layout as MediaTextBlockLayout}
      contentBackground={textBackground as HarviaBackgroundColor}
      media={<VideoEmbed {...props.video} />}
      content={
        <>
          {props.title && <h3>{props.title}</h3>}
          <DatoStructuredTextBlock data={props.content} shiftHeadings={2} />
        </>
      }
    />
  )
}

export default DatoVideoWithText

export const query = graphql`
  fragment DatoVideoWithText on DatoCmsVideoWithText {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    video {
      url
      title
      provider
      providerUid
      thumbnailUrl
      width
      height
    }

    textBackground
    layout
    title
    content {
      value
      links {
        __typename
        ... on DatoCmsRouteLink {
          ...DatoRouteLink
        }
        ... on DatoCmsExternalUrl {
          ...DatoExternalUrl
        }
        ... on DatoCmsArticleLink {
          ...DatoArticleLink
        }
        ... on DatoCmsProductLink {
          ...DatoProductLink
        }
        ... on DatoCmsProductFinderLink {
          ...DatoProductFinderLink
        }
      }
    }
  }
`
